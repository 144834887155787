import { gql } from 'graphql-request';

import { USER_FRAGMENT } from '../fragments';

export const CHANGE_PASSWORD = gql`
  mutation changePassword($newPassword: String!, $oldPassword: String!) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
