import { SearchParamsProps, TableKeys } from 'consts';

import { useTablePagination } from './useTablePagination';
import { useTableSortHandler } from './useTableSortHandler';
import { useColumnVisibility } from './useColumnVisibility';
import { SortTypeInput } from 'types';

interface IUseStandardDataGridProps<TGrid> {
  updateGrid: (props: SearchParamsProps) => void;
  grid: TGrid;
  tableKey: TableKeys;
}

export const useStandardDataGrid = <
  TGrid extends { sort?: SortTypeInput; limit?: number; offset?: number },
>(
  props: IUseStandardDataGridProps<TGrid>,
) => {
  const { updateGrid, grid, tableKey } = props;

  const tablePagination = useTablePagination({ updateGrid, grid, tableKey });
  const sortHandler = useTableSortHandler(grid.sort);

  const columnVisibility = useColumnVisibility(tableKey);

  return {
    ...tablePagination,
    ...sortHandler,
    ...columnVisibility,
    autoHeight: true,
    disableColumnFilter: true,
    hideFooterSelectedRowCount: true,
  };
};
