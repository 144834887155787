import { SortDirEnum, SortTypeInput } from 'types';
import { TableKeys } from 'consts';

export const initialPagination = (tableKey: TableKeys) => {
  const storedPageSize = () => localStorage.getItem(`${tableKey}ListPageSize`) ?? '10';
  const initialPageSize = () => +storedPageSize();
  const initialCurrentPage = 1;

  const pagination = {
    limit: initialPageSize(),
    offset: initialPageSize() * (initialCurrentPage - 1),
  };

  return pagination;
};

export const sortInfo = (key: string, gridSort: SortTypeInput): boolean | 'ascend' | 'descend' => {
  if (key === gridSort?.field) {
    return gridSort.dir === SortDirEnum.ASC ? 'ascend' : 'descend';
  }

  return false;
};
