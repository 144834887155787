import { isArray } from 'lodash';
import { useIntl } from 'react-intl';

import { logger } from 'utils';
import { EnumTranslationResponse } from 'types';

import { useGroupedPermissions } from './useGroupedPermissions';
import { useNotify } from './useNotify';

export const usePermissions = () => {
  const { groupedPermissions, isLoading, error, refetch } = useGroupedPermissions();
  const intl = useIntl();
  const { notifyError } = useNotify();

  const findPermissionKeys = (permissionIds: string[]): string[] | null => {
    if (isLoading || error || !isArray(groupedPermissions)) return null;

    const allPermissions = groupedPermissions?.flatMap(group => group.options);

    const keys = permissionIds.map(
      permissionId =>
        allPermissions?.find(({ id }: EnumTranslationResponse) => id === permissionId)?.key,
    );

    const filteredKeys = keys?.filter(i => {
      if (!i) {
        logger({
          type: 'error',
          message: 'usePermissions - Permission not found in keys',
          extra: i,
        });
      }

      return !!i;
    });

    if (!filteredKeys) {
      notifyError(
        intl.formatMessage({
          id: 'usePermissions.error.message',
          defaultMessage: 'There was an error filtering permissions.',
        }),
      );

      return null;
    }

    // @ts-ignore
    return filteredKeys;
  };

  return {
    groupedPermissions,
    isLoading,
    error,
    refetch,
    findPermissionKeys,
  };
};
