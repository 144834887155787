import { get } from 'lodash';
import { useTicketTypesQuery } from 'hooks';
import { useMemo } from 'react';
import { TypeFragment } from 'types';

interface IUseTicketTypes {
  skip?: boolean;
}

export const useTicketTypes = (props?: IUseTicketTypes) => {
  const { data, ...rest } = useTicketTypesQuery(undefined, { enabled: !props?.skip });

  const ticketTypes: TypeFragment[] = useMemo(() => get(data, `ticketTypes`, []), [data]);

  return {
    ticketTypes,
    ...rest,
  };
};
