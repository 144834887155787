import { useUser as useCurrentUser } from 'context/UserProvider';
import { Roles } from 'consts';

export const useIsAuthorized = () => {
  const { role } = useCurrentUser();

  const isAuthorized = (requiredRole: Roles) => {
    return role === requiredRole;
  };

  const isAuthorizedToAny = (requiredRoles: Roles[]) => {
    return requiredRoles.includes(role);
  };

  const isAdmin = isAuthorized(Roles.ADMIN) || isAuthorized(Roles.SUPER_ADMIN);
  const isSuperAdmin = isAuthorized(Roles.SUPER_ADMIN);

  return { isAuthorized, isAuthorizedToAny, isAdmin, isSuperAdmin };
};
