import { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from './QueryClientProvider';
import { ThemeProvider } from './ThemeProvider';
import { AuthProvider } from './AuthProvider';
import { UserProvider } from './UserProvider';
import { LanguageProvider } from './LanguageProvider';
import { GlobalStateProvider } from './GlobalStateProvider';

interface IAppProvidersProps {
  children: ReactNode;
}

export const AppProviders = (props: IAppProvidersProps) => (
  <GlobalStateProvider>
    <QueryClientProvider>
      <AuthProvider>
        <UserProvider>
          <ThemeProvider>
            <LanguageProvider>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {props.children}
              </SnackbarProvider>
            </LanguageProvider>
          </ThemeProvider>
        </UserProvider>
      </AuthProvider>
    </QueryClientProvider>
  </GlobalStateProvider>
);
