import { get } from 'lodash';
import { useQuery } from 'react-query';

import { DealTypesQuery, DealTypesQueryVariables } from 'types';
import { queries } from 'services';
import { client } from 'client';

interface IUseDealTypes {
  skip?: boolean;
}

export const useDealTypes = (props?: IUseDealTypes) => {
  const { data, isLoading, error } = useQuery<DealTypesQuery, DealTypesQueryVariables>(
    'dealTypes',
    async () => await client.request(queries.DEAL_TYPES),
    { enabled: !props?.skip },
  );

  return {
    data: get(data, `dealTypes`, []),
    loading: isLoading,
    error,
  };
};
