import { gql } from 'graphql-request';

export const FILES = gql`
  query files {
    files {
      id
      title
      url
    }
  }
`;
