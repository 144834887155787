import { get } from 'lodash';
import { useRolesQuery } from 'hooks';
import { useMemo } from 'react';
import { RoleResponse } from 'types';

export const useRoles = () => {
  const { data, ...rest } = useRolesQuery();

  const roles = useMemo(() => get(data, `roles`, []), [data]) as RoleResponse[];

  return {
    roles,
    ...rest,
  };
};
