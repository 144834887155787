import { i18nConfigDefaults, i18nSupported, Languages, Locales } from 'consts';

import translations from './translations';

const getLocale = (/* userLocale: string | undefined, */ defaultLocale: Locales) => {
  // const appLocale = () => {
  //   if (!!userLocale) {
  //     return userLocale.replace('_', '-');
  //   }

  //   if (!!localStorage.getItem('appLocale')) {
  //     const localeFromLS = localStorage.getItem('appLocale');

  //     if (!localeFromLS) return defaultLocale;

  //     if (i18nSupported.locales.includes(localeFromLS)) {
  //       return localeFromLS;
  //     }
  //   }

  //   const navigatorLocale = window.navigator.language;

  //   if (i18nSupported.locales.includes(navigatorLocale)) {
  //     return navigatorLocale;
  //   }
  // };

  const locale = ('en-GB' /*appLocale()*/ ?? defaultLocale) as Locales;

  const detectedLang = locale.split('-')[0] as Languages;
  // const delectedLocale = locale.split('-')[1];

  const lang = ((i18nSupported.languages.includes(detectedLang) && detectedLang) ||
    i18nConfigDefaults.language) as string;
  const trs: { [index: string]: unknown } = translations;
  const messages = trs[lang];

  return {
    locale,
    lang,
    messages,
  };
};

export default getLocale;
