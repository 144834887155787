import axios, { AxiosError, AxiosResponse } from 'axios';
import { noop } from 'lodash';

import { logger } from './logger';
import { apiEndpoint } from 'consts';

export const uploadAttachments = (
  files: { [index: string]: unknown }[],
  url: string,
  onSuccess: (response: AxiosResponse) => void = noop,
  onError: (error: AxiosError) => void = noop,
  onUploadProgress: (progressEvent: unknown) => void = noop,
) => {
  const formData = new FormData();

  files.forEach((file: { file: Blob }) => {
    formData.append('files[]', file.file);
  });

  return axios({
    url: `${apiEndpoint}${url}`,
    method: 'POST',
    withCredentials: true,
    data: formData,
    onUploadProgress: onUploadProgress,
  })
    .then((response: unknown) => {
      onSuccess(response);
    })
    .catch((error: unknown) => {
      logger(error);
      onError(error);
    });
};
