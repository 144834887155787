import { RequestInit } from 'graphql-request/dist/types.dom';
import { client } from './client';
import { responseMiddleware } from 'utils';

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers'],
) => {
  return async (): Promise<TData> => {
    const res = await client.rawRequest<TData, TVariables>(query, variables, headers);

    responseMiddleware(res.headers);

    return res.data;
  };
};
