import { defineMessages } from 'react-intl';

import {
  DomainFragment,
  PriorityFragment,
  ProjectFragment,
  StatusFragment,
  UserFragment,
} from 'types';

export enum TicketsTableHeaderKeys {
  TICKET_NAME = 'ticketName',
  TICKET_TYPE = 'ticketType',
  UPDATED_BY = 'updatedBy',
  ASSIGNED = 'assigned',
  LAST_UPDATE = 'lastUpdate',
  STATUS = 'status',
  PRIORITY = 'priority',
  DEADLINE = 'deadline',
  OWNER = 'owner',
  DOMAIN_NAME = 'domainName',
  CREATED_AT = 'createdAt',
  START = 'start',
}

export enum TicketsQuerySortKeys {
  TYPE = 'typeValue',
  UPDATED_AT = 'updatedAt',
  ASSIGNED = 'assignedUserId',
  OWNER = 'ownerUserId',
  STATUS = 'statusValue',
  PRIORITY = 'priorityValue',
  DEADLINE = 'deadline',
  START = 'start',
  TITLE = 'title',
  DOMAIN_NAME = 'domainName',
  CREATED_AT = 'createdAt',
}

export enum TicketsQueryFilterKeys {
  PROJECT_ID = 'projectId',
  TYPE_ID = 'typeId',
  STATUS_ID = 'statusId',
  PRIORITY_ID = 'priorityId',
  ASSIGNED_USER_ID = 'assignedUserId',
  OWNER_USER_ID = 'ownerUserId',
  START = 'start',
  DEADLINE = 'deadline',
  CLOSED = 'closed',
  DOMAIN_ID = 'domainId',
  WATCHED_BY = 'watchedBy',
  IMPORT_STATUS_ID = 'importStatusId',
  IMPORT_TYPE_ID = 'importTypeId',
}

export interface ITableFilters {
  projects: ProjectFragment[];
  ticketStatuses: StatusFragment[];
  ticketPriorities: PriorityFragment[];
  users: UserFragment[];
  domains?: DomainFragment[];
  isLoading: boolean;
  error: any[] | null;
}

/**
 * i18n Messages
 */
export const ticketListMessages = defineMessages({
  searchFieldPlaceholder: {
    id: 'ticketListMessages.searchFieldPlaceholder',
    defaultMessage: 'Search tickets',
  },
  addButtonText: {
    id: 'ticketListMessages.addButtonText',
    defaultMessage: 'Add ticket',
  },

  projectId: {
    id: 'ticketListMessages.projectId',
    defaultMessage: 'Project',
  },
  typeId: {
    id: 'ticketListMessages.typeId',
    defaultMessage: 'Type',
  },
  statusId: {
    id: 'ticketListMessages.statusId',
    defaultMessage: 'Status',
  },

  start: {
    id: 'ticketListMessages.start',
    defaultMessage: 'Start',
  },
  deadline: {
    id: 'ticketListMessages.deadline',
    defaultMessage: 'Deadline',
  },
  openClosed: {
    id: 'ticketListMessages.openClosed',
    defaultMessage: 'Open/Closed',
  },
  open: {
    id: 'ticketListMessages.open',
    defaultMessage: 'Open',
  },
  closed: {
    id: 'ticketListMessages.closed',
    defaultMessage: 'Closed',
  },
  domains: {
    id: 'ticketListMessages.domains',
    defaultMessage: 'Domeny',
  },
});
