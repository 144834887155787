import { gql } from 'graphql-request';

import { DEAL_IN_THE_DOMAIN_LIST_FRAGMENT } from './dealInTheDomainList';
import { PARTNER_USER_FRAGMENT } from './partnerUser';
import { USER_BASIC_FRAGMENT } from './userBasic';

export const DOMAIN_IN_THE_LIST_FRAGMENT = gql`
  fragment domainInTheList on Domain {
    id
    name
    updated_at
    first_deal_start_date

    assignedPartnerUsers {
      ...userBasic
    }
    newestGridDeal {
      ...dealInTheDomainList
    }
  }
  ${PARTNER_USER_FRAGMENT}
  ${USER_BASIC_FRAGMENT}
  ${DEAL_IN_THE_DOMAIN_LIST_FRAGMENT}
`;
