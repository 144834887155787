import { get } from 'lodash';
import { useDomainAdminQuery, useDomainQuery, useIsAuthorized } from 'hooks';
import { useMemo } from 'react';
import { IDomain, IDomainAdmin } from '../types';

export const useDomain = (id: string) => {
  const { isAdmin } = useIsAuthorized();

  const userResult = useDomainQuery({ id }, { enabled: !isAdmin });
  const adminResult = useDomainAdminQuery({ id }, { enabled: isAdmin });

  const result = isAdmin ? adminResult : userResult;

  const domain: IDomain | IDomainAdmin | null = useMemo(() => {
    const obj = get(result.data, `domain`, null);

    if (!obj) return null;

    if ('partnerUsers' in obj) {
      obj.type = 'ADMIN';
    } else {
      obj.type = 'USER';
    }

    return obj;
  }, [result.data]);

  return {
    domain,
    ...result,
  };
};
