import { gql } from 'graphql-request';

export const TICKET_STATUS_FRAGMENT = gql`
  fragment status on Status {
    id
    value
    treat_as_closed
    color
    default
    archived
  }
`;
