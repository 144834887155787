import { gql } from 'graphql-request';

import { ROLE_FRAGMENT } from '../fragments';

export const SYNC_ROLE_PERMISSIONS = gql`
  mutation syncRolePermissions($roleId: String!, $permissions: [PermissionEnum!]!) {
    syncRolePermissions(roleId: $roleId, permissions: $permissions) {
      ...role
    }
  }
  ${ROLE_FRAGMENT}
`;
