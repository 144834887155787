import { QueryClient, QueryClientProvider as QCProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ReactNode } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
      retry: 1,
      cacheTime: 1000 * 60 * 20,
    },
  },
});

interface IProps {
  children: ReactNode;
}

export const QueryClientProvider = (props: IProps) => {
  return (
    <QCProvider client={queryClient}>
      {props.children} <ReactQueryDevtools initialIsOpen />
    </QCProvider>
  );
};
