import { get } from 'lodash';
import { useImportedTicketQuery } from 'hooks';
import { useMemo } from 'react';
import { ImportedTicketFragment } from 'types';

interface IUseImportedTicket {
  id: string;
}

export const useImportedTicket = ({ id }: IUseImportedTicket) => {
  const { data, ...rest } = useImportedTicketQuery({ id });

  const ticket = useMemo(() => get(data, `ticket`, null) as ImportedTicketFragment | null, [data]);

  return {
    data: ticket,
    ...rest,
  };
};
