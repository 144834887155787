import { gql } from 'graphql-request';

export const DEAL_FRAGMENT = gql`
  fragment deal on Deal {
    id
    active
    amount
    currency
    type
    type_name
    orig_real_start_date
  }
`;
