import { gql } from 'graphql-request';
import { GROUPED_PERMISSIONS_FRAGMENT } from '../fragments';

export const GROUPED_PERMISSIONS = gql`
  query groupedPermissions {
    groupedPermissions {
      ...groupedPermissions
    }
  }
  ${GROUPED_PERMISSIONS_FRAGMENT}
`;
