import { gql } from 'graphql-request';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const NOTIFICATION_FRAGMENT = gql`
  fragment notification on Notification {
    id
    type
    data
    created_at
    read_at
    owner {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
