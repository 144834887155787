import { gql } from 'graphql-request';

export const TRANSLATABLES = gql`
  query translatables {
    translatables {
      id
      value
      rows {
        id
        type
        translations {
          language_code
          value
        }
      }
    }
  }
`;
