import { gql } from 'graphql-request';

import { CLIENT_SETTINGS_FRAGMENT } from '../fragments';

export const CLIENT_SETTINGS = gql`
  query clientSettings($id: ID!) {
    user(id: $id) {
      ...clientSettings
    }
  }
  ${CLIENT_SETTINGS_FRAGMENT}
`;
