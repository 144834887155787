import { useCallback, useState } from 'react';

export const useGridState = <TGrid>(initialGrid: TGrid) => {
  const [grid, setGrid] = useState<TGrid>(initialGrid);

  const updateGrid = useCallback((newGrid: TGrid) => {
    setGrid(newGrid);
  }, []);

  return [grid, updateGrid] as const;
};
