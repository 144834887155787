import { gql } from 'graphql-request';

import { TICKET_TYPE_FRAGMENT } from '../fragments';

export const TICKET_TYPES = gql`
  query ticketTypes($includeArchived: Boolean) {
    ticketTypes(includeArchived: $includeArchived) {
      ...type
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;
