import { MonitorsQueryVariables } from 'types';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useMonitorsQuery } from '../__generated__/gqlHooks';

interface IUseMonitors {
  variables?: MonitorsQueryVariables;
}

export const useMonitors = (props: IUseMonitors) => {
  const { variables = {} } = props;

  const { data, ...rest } = useMonitorsQuery(variables);

  const defaultData = { total: 0, rows: [] };
  const monitors = useMemo(() => get(data, `monitors`, defaultData), [data]);

  return {
    monitors,
    ...rest,
  };
};
