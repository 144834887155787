import { gql } from 'graphql-request';

export const TICKET_TYPE_FRAGMENT = gql`
  fragment type on Type {
    id
    value
    color
    default
    archived
  }
`;
