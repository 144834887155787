import { gql } from 'graphql-request';

import { TICKET_STATUS_FRAGMENT } from '../fragments';

export const ADD_TICKET_STATUS = gql`
  mutation addTicketStatus($value: String!, $color: ColorPaletteEnum!, $treatAsClosed: Boolean) {
    addTicketStatus(value: $value, color: $color, treatAsClosed: $treatAsClosed) {
      ...status
    }
  }
  ${TICKET_STATUS_FRAGMENT}
`;
