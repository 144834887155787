import { gql } from 'graphql-request';

export const GLOBAL_SEARCH = gql`
  query globalSearch($domainsGrid: DomainGridInput) {
    domains(grid: $domainsGrid) {
      rows {
        id
        name
      }
    }
  }
`;
