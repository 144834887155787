import { gql } from 'graphql-request';

import { USER_FRAGMENT } from '../fragments';

export const UPDATE_USER_DOMAIN_NOTIFICATIONS = gql`
  mutation updateUserDomainNotifications($id: ID!, $domainId: String!, $isSubscribed: Boolean!) {
    updateUserDomainNotifications(userId: $id, domainId: $domainId, isSubscribed: $isSubscribed) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
