import { gql } from 'graphql-request';

import { PERMISSION } from './permission';

export const GROUPED_PERMISSIONS_FRAGMENT = gql`
  fragment groupedPermissions on GroupedPermissionsResponse {
    id
    value
    options {
      ...permission
    }
  }
  ${PERMISSION}
`;
