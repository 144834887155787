import { get } from 'lodash';
import { useMemo } from 'react';
import { useTicketStatusesQuery } from 'hooks';
import { StatusFragment } from 'types';

interface IUseTicketStatuses {
  skip?: boolean;
}

export const useTicketStatuses = (props?: IUseTicketStatuses) => {
  const { data, ...rest } = useTicketStatusesQuery(undefined, { enabled: !props?.skip });

  const ticketStatuses: StatusFragment[] = useMemo(() => get(data, `ticketStatuses`, []), [data]);

  return {
    ticketStatuses,
    ...rest,
  };
};
