import { gql } from 'graphql-request';

export const CRM_COMPANIES = gql`
  query crmCompanies {
    crmCompanies {
      id
      fullName
    }
  }
`;
