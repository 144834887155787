import { gql } from 'graphql-request';

import { TICKET_FRAGMENT } from '../fragments';

export const WATCH_TICKET = gql`
  mutation watchTicket($ticketId: ID!, $userIds: [ID]!) {
    watchTicket(ticketId: $ticketId, userIds: $userIds) {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
