import { get } from 'lodash';
import { useTicketQuery } from 'hooks';
import { useMemo } from 'react';
import { TicketFragment } from 'types';

interface IUseTicket {
  id: string;
}

export const useTicket = ({ id }: IUseTicket) => {
  const { data, ...rest } = useTicketQuery({ id });

  const ticket = useMemo(() => get(data, `ticket`, null) as TicketFragment | null, [data]);

  return {
    data: ticket,
    ...rest,
  };
};
