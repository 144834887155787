import { gql } from 'graphql-request';

import { PHRASE_FRAGMENT } from '../fragments';

export const PHRASES = gql`
  query phrases($grid: PhraseGridInput) {
    phrases(grid: $grid) {
      total
      rows {
        ...phrase
      }
    }
  }
  ${PHRASE_FRAGMENT}
`;
