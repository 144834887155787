import { gql } from 'graphql-request';
import { DEAL_IN_THE_DOMAIN_LIST_FRAGMENT } from './dealInTheDomainList';

export const DEAL_IN_THE_DOMAIN_LIST_FRAGMENT__ADMIN = gql`
  fragment dealInTheDomainListADMIN on Deal {
    ...dealInTheDomainList
    name
    import_id
  }
  ${DEAL_IN_THE_DOMAIN_LIST_FRAGMENT}
`;
