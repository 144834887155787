import { gql } from 'graphql-request';

import { TICKET_TODO_FRAGMENT } from '../fragments';

export const TICKET_TODOS = gql`
  query ticketTodos($ticketId: ID!) {
    ticketTodos(ticketId: $ticketId) {
      ...ticketTodo
    }
  }
  ${TICKET_TODO_FRAGMENT}
`;
