import { APP_VERSION } from 'consts';

import { logger } from './logger';
import * as Dom from 'graphql-request/dist/types.dom';

const event = new Event('update-app');

export const responseMiddleware = (headers: Dom.Headers) => {
  try {
    const map = headers?.map;

    if (!map) return;

    if ('x-client-version' in map) {
      const versionFromTheServer = map['x-client-version'];

      if (!APP_VERSION || !versionFromTheServer) return;

      if (versionFromTheServer !== APP_VERSION) {
        sessionStorage.setItem('is-app-version-outdated', 'true');
        window.dispatchEvent(event);
      }
    }
  } catch (err: unknown) {
    logger(err);
  }
};
