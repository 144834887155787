import { gql } from 'graphql-request';

export const DOMAINS_WITH_ACCOUNT_ID = gql`
  query domainsWithAccountId {
    domainsWithAccountId {
      id
      name
      account_id
    }
  }
`;
