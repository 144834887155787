import { useMutation } from 'react-query';
import { client } from 'client';
import {
  ImpersonateMutation,
  ImpersonateMutationVariables,
  LeaveImpersonationMutation,
  LeaveImpersonationMutationVariables,
} from 'types';
import { mutations } from 'services';

interface IHandleImpersonateUser {
  userId: string;
  accountId?: string;
}

export const useImpersonateUser = () => {
  const { mutateAsync: impersonateUser } = useMutation<
    ImpersonateMutation,
    ImpersonateMutationVariables
  >(async variables => {
    return client.request(mutations.IMPERSONATE, variables);
  });

  const handleImpersonateUser = ({ userId, accountId }: IHandleImpersonateUser) => {
    return impersonateUser({ userId, accountId }).then(() => {
      window.location.reload();
    });
  };

  return [handleImpersonateUser];
};

export const useLeaveImpersonation = () => {
  const { mutateAsync: leaveImpersonation } = useMutation<
    LeaveImpersonationMutation,
    LeaveImpersonationMutationVariables
  >(() => {
    return client.request(mutations.LEAVE_IMPERSONATION);
  });

  const handleLeaveImpersonation = () => {
    return leaveImpersonation().then(() => {
      localStorage.removeItem('zeus-domain');
      window.location.reload();
    });
  };

  return [handleLeaveImpersonation];
};
