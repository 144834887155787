export enum Locales {
  PL_PL = 'pl-PL',
  EN_GB = 'en-GB',
}

export enum MuiLocales {
  plPL = 'plPL',
  enGB = 'enGB',
}

export enum Languages {
  PL = 'pl',
  EN = 'en',
}

export enum UnderscoredLocales {
  pl_PL = 'pl_PL',
  en_GB = 'en_GB',
}

export const i18nConfigDefaults = {
  locale: Locales.EN_GB,
  muiLocale: MuiLocales.enGB,
  language: Languages.EN,
};

export const i18nSupported = {
  locales: [Locales.PL_PL, Locales.EN_GB],
  languages: [Languages.PL, Languages.EN],
  muiLocales: [MuiLocales.plPL, MuiLocales.enGB],
};
