import { StrictMode } from 'react';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AppProviders } from 'context'; // @ts-ignore
import { createRoot } from 'react-dom/client';

import { initLogServices } from 'utils';

initLogServices();
// @ts-ignore
const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
}
