import { useCallback, useState } from 'react';
import { GridSearchParamsTypes, SearchParamsProps, TableKeys } from 'consts';

const rowsPerPageOptions = [10, 20, 50, 100];

interface UseTablePaginationProps<TGrid> {
  updateGrid: (props: SearchParamsProps) => void;
  grid: TGrid;
  tableKey: TableKeys;
}

export const useTablePagination = <TGrid extends { limit?: number; offset?: number }>(
  props: UseTablePaginationProps<TGrid>,
) => {
  const { grid, updateGrid, tableKey } = props;

  const [totalItems, setTotalItems] = useState(0);

  const handleUpdate = (currentPage: number, pageSize: number) => {
    updateGrid({
      type: GridSearchParamsTypes.UPDATE_PAGINATION,
      payload: {
        offset: `${pageSize * currentPage}`,
        limit: `${pageSize}`,
      },
    });
  };

  const pageSize = grid.limit ?? 10;
  const currentPage = grid.offset && grid.limit ? grid.offset / grid.limit : 0;

  const onPageSizeChange = (newPageSize: number) => {
    if (!newPageSize) return;

    tableKey && localStorage.setItem(`${tableKey}ListPageSize`, newPageSize.toString());

    handleUpdate(0, newPageSize);
  };

  const onPageChange = (newPage: number) => {
    if (typeof newPage !== 'number') return;

    handleUpdate(newPage, pageSize);
  };

  const updateTotalItems = useCallback((items: number) => {
    setTotalItems(items);
  }, []);

  const page = currentPage;

  const rowCount = totalItems || 0;

  return {
    page,
    rowCount,
    onPageChange,
    pageSize,
    rowsPerPageOptions,
    onPageSizeChange,
    updateTotalItems,
    totalItems,
    paginationMode: 'server',
    pagination: true,
  };
};
