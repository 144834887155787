import { gql } from 'graphql-request';

import { MONITOR_FRAGMENT } from '../fragments';

export const MONITOR = gql`
  query monitor($id: String!) {
    monitor(id: $id) {
      ...monitor
    }
  }
  ${MONITOR_FRAGMENT}
`;
