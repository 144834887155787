import { ThemeProvider as SCProvider } from 'styled-components';
import { ReactNode, useMemo } from 'react';
import { createTheme, ThemeProvider as MUIProvider } from '@mui/material/styles';
import { enUS as coreEnUS } from '@mui/material/locale';
import { enUS } from '@mui/x-data-grid';
import { muiDarkTheme, muiKiwiTheme, muiLightTheme, theme } from 'theme';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { useGlobalState } from './GlobalStateProvider';
import { AppDomain } from '../consts';

interface IStyledComponentsProviderProps {
  children: ReactNode;
}

const StyledComponentsProvider = (props: IStyledComponentsProviderProps) => (
  <SCProvider theme={theme}>{props.children}</SCProvider>
);

interface IMaterialUIProviderProps {
  children: ReactNode;
}

const selectTheme = (currentTheme: 'dark' | 'light' | 'system', prefersDarkMode: boolean) => {
  if (AppDomain === 'kiwi') {
    return muiKiwiTheme;
  }
  if (currentTheme === 'system') return prefersDarkMode ? muiDarkTheme : muiLightTheme;

  return currentTheme === 'dark' ? muiDarkTheme : muiLightTheme;
};

const MaterialUIProvider = (props: IMaterialUIProviderProps) => {
  const state = useGlobalState();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const materialUITheme = useMemo(() => {
    const selectedTheme = selectTheme(state.mode, prefersDarkMode);

    return createTheme(selectedTheme, enUS, coreEnUS);
  }, [state.mode, prefersDarkMode]);

  return <MUIProvider theme={materialUITheme}>{props.children}</MUIProvider>;
};

interface IThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = (props: IThemeProviderProps) => (
  <MaterialUIProvider>
    <CssBaseline enableColorScheme />
    <StyledComponentsProvider>{props.children}</StyledComponentsProvider>
  </MaterialUIProvider>
);
