import { gql } from 'graphql-request';

import { DOMAIN_IN_THE_LIST_FRAGMENT__ADMIN } from '../fragments';

export const DOMAIN_LIST__ADMIN = gql`
  query domainListADMIN($grid: DomainGridInput) {
    domains(grid: $grid) {
      total
      rows {
        ...domainInTheListADMIN
      }
    }
  }
  ${DOMAIN_IN_THE_LIST_FRAGMENT__ADMIN}
`;
