import { gql } from 'graphql-request';

export const COLOR_PALETTE = gql`
  query colorPalette {
    colorPalette {
      id
      key
    }
  }
`;
