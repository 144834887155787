export enum FilterTypes {
  SELECT = 'select',
  MULTI_SELECT = 'multiselect',
  TEXT = 'text',
  DATE_RANGE = 'daterange',
  DATE = 'date',
  RANGE = 'range',
  DYNAMIC_SEARCH = 'dynamicSearch',
  CHECKBOX = 'checkbox',
}
