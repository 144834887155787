import { gql } from 'graphql-request';

import { USER_FRAGMENT } from '../fragments';

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $userInput: UpdateUserInput!) {
    updateUser(id: $id, userInput: $userInput) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
