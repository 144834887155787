import { MessageDescriptor, useIntl } from 'react-intl';
import { ReactElement } from 'react';

type MessageFormatPrimitiveValue = string | number | boolean | null | undefined;
type MessageFormatValues = Record<string, MessageFormatPrimitiveValue | ReactElement>;

/**
 * useFormatMessage
 */
export const useTranslate = () => {
  const intl = useIntl();

  const t = (message: MessageDescriptor, values?: MessageFormatValues) =>
    intl.formatMessage(message, values);

  return t;
};
