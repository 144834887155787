import { useUpdateGridSearchParams } from './useUpdateGridSearchParams';
import { GridSortModel, SortTypeInput } from 'types';
import { GridSearchParamsTypes } from 'consts';

export const useTableSortHandler = (gridSort: SortTypeInput | null | undefined) => {
  const [updateSearchParams] = useUpdateGridSearchParams();

  const sortModel: GridSortModel = gridSort
    ? [{ field: gridSort.field, sort: gridSort.dir?.toLowerCase() }]
    : [];

  const onSortModelChange = (newSortModel: GridSortModel) => {
    const sortItem = newSortModel[0];

    if (!sortItem) {
      updateSearchParams({ type: GridSearchParamsTypes.REMOVE_PARAM, payload: 'sort' });

      return;
    }

    const payload = {
      field: sortItem.field,
      dir: sortItem.sort?.toUpperCase(),
    };

    updateSearchParams({ type: GridSearchParamsTypes.UPDATE_SORT, payload });
  };

  return { sortModel, onSortModelChange, sortingMode: 'server' };
};
