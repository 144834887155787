import { gql } from 'graphql-request';

import { TICKET_TODO_FRAGMENT } from '../fragments';

export const ADD_TODOS = gql`
  mutation addTodos($ticketId: ID!, $todos: [TodoInput!]!) {
    addTodos(ticketId: $ticketId, todos: $todos) {
      ...ticketTodo
    }
  }
  ${TICKET_TODO_FRAGMENT}
`;
