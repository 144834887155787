import { get } from 'lodash';
import { useGroupedPermissionsQuery } from 'hooks';
import { useMemo } from 'react';
import { GroupedPermissionsFragment } from 'types';

export const useGroupedPermissions = () => {
  const { data, isLoading, error, refetch } = useGroupedPermissionsQuery();

  const groupedPermissions = useMemo(
    () => get(data, `groupedPermissions`, []),
    [data],
  ) as GroupedPermissionsFragment[];

  return {
    groupedPermissions,
    isLoading,
    error,
    refetch,
  };
};
