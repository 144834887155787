import { GraphQLClient } from 'graphql-request';

import { apiGqlEndpoint } from 'consts';
/*
 class GQLClient extends GraphQLClient {
 async request<T = any, V = Variables>(
 documentOrOptions: RequestDocument | RequestOptions<V>,
 variables?: V,
 requestHeaders?: Dom.RequestInit['headers'],
 ): Promise<T> {

 super.rawRequest(documentOrOptions, variables, requestHeaders)

 }
 }*/

export const client = new GraphQLClient(apiGqlEndpoint);
