import { useEffect, useState } from 'react';

import { NewContentTypes } from 'consts';

export const useContentHtmlLoader = () => {
  //const newContentHtml = useReactiveVar(contentHtmlToLoad);
  const newContentHtml = '';
  const [contentHtml, setContentHtml] = useState<NewContentTypes>();

  useEffect(() => {
    if (newContentHtml) {
      setContentHtml({ type: 'loadedHtml', contentHtml: newContentHtml });

      // contentHtmlToLoad(null);
    }
  }, [newContentHtml]);

  return [contentHtml, setContentHtml];
};
