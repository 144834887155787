import { gql } from 'graphql-request';
import { USER_BASIC_FRAGMENT } from './userBasic';

export const TICKET_USERS_FRAGMENT = gql`
  fragment ticketUsers on Ticket {
    id
    owner {
      ...userBasic
    }
    assigned {
      ...userBasic
    }
    watchers {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
