import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';
import { noop } from 'lodash';

/**
 * Context
 */

interface IDispatchProps {
  type: 'CHANGE_MODE';
  payload: 'dark' | 'light' | 'system';
}

interface IGlobalState {
  mode: 'dark' | 'light' | 'system';
  dispatch: (props: IDispatchProps) => void;
}

function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts[1].split(';').shift();
  }

  return undefined;
}

const GlobalState = createContext<IGlobalState | undefined>(undefined);

const initialState = { mode: 'light' as const, dispatch: noop };
const init = initState => {
  const initialMode = getCookie('paletteMode') || 'light';

  return { mode: initialMode, dispatch: noop };
};

const reducer = (state: IGlobalState = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_MODE':
      return { ...state, mode: action.payload };

    default:
      throw new Error('Missed switch case in Global State');
  }
};

/**
 * Provider
 */
interface IGlobalStateProviderProps {
  children: ReactNode;
}

const GlobalStateProvider = (props: IGlobalStateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const value = useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

  return <GlobalState.Provider value={value}>{props.children}</GlobalState.Provider>;
};

/**
 * Hook
 */
const useGlobalState = () => {
  const context = useContext(GlobalState);

  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }

  return context;
};

export { GlobalStateProvider, useGlobalState };
