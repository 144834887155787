import { defineMessages } from 'react-intl';

/**
 * i18n Validation Errors
 */
export const i18nErrors = defineMessages({
  required: {
    id: 'consts.validationErrors.messages.required',
    defaultMessage: 'Required',
  },
  email: {
    id: 'consts.validationErrors.messages.email',
    defaultMessage: 'Please provide correct email',
  },
  passwordMinLength: {
    id: 'consts.validationErrors.messages.passwordMinLength',
    defaultMessage: 'Password must have at least 8 characters',
  },
  passwordsMustMatch: {
    id: 'consts.validationErrors.messages.passwordsMustMatch',
    defaultMessage: 'Passwords must be the same',
  },
  passwordInstruction: {
    id: 'consts.validationErrors.messages.passwordInstruction',
    defaultMessage:
      'Password should be a combination of at least 8 characters, including lower case, upper case, digit and special sign',
  },
  numbersOnly: {
    id: 'consts.validationErrors.messages.numbersOnly',
    defaultMessage: 'Numbers only',
  },
  ticketStartDate: {
    id: 'consts.validationErrors.messages.ticketStartDate',
    defaultMessage: "Ticket's start can't be after deadline",
  },
  cacheUpdateError: {
    id: 'consts.validationErrors.messages.cacheUpdateError',
    defaultMessage: 'There was a problem fetching new data. Please reload the website.',
  },
});
