import { gql } from 'graphql-request';

export const ROLE_FRAGMENT = gql`
  fragment role on Role {
    id
    name
    title
    permissions {
      id
      name
    }
  }
`;
