import { gql } from 'graphql-request';
import { TICKET_COMMENT_FILE_FRAGMENT } from './ticketCommentFile';

export const IMPORTED_TICKET_COMMENT_FRAGMENT = gql`
  fragment importedTicketComment on TicketComment {
    id
    content_html
    created_at
    file {
      ...ticketCommentFile
    }
  }

  ${TICKET_COMMENT_FILE_FRAGMENT}
`;
