import { gql } from 'graphql-request';

import { USER_IN_THE_LIST_FRAGMENT } from '../fragments';

export const USER_LIST = gql`
  query userList($grid: UserGridInput) {
    users(grid: $grid) {
      total
      rows {
        ...userInTheList
      }
    }
  }
  ${USER_IN_THE_LIST_FRAGMENT}
`;
