import { Totals } from 'types';

export enum CardFilterKeys {
  FILTER_DEADLINE = 'deadline',
  FITLER_CLOSED = 'closed',
}

export enum CardFilterOptions {
  OPEN = 'open',
  CLOSED = 'closed',
  OVERDUE = 'overdue',
  TODAY = 'today',
  NEXT_7_DAYS = 'thisWeek',
  DOMAINS = 'domains',
}

export type CardFilter = Pick<
  Totals,
  'thisWeek' | 'overdue' | 'today' | 'open' | 'closed' | 'domains'
>;
