import { gql } from 'graphql-request';

export const LOCALES = gql`
  query locales {
    locales {
      id
      key
      value
    }
  }
`;
