import { createContext, FC, useContext } from 'react';
import { useMutation } from 'react-query';
import { client } from 'client';
import { mutations } from 'services';
import {
  ActiveUserFragment,
  LoginUserMutation,
  LoginUserMutationVariables,
  LogoutUserMutation,
} from 'types';
import { useActiveUserQuery } from 'hooks';
import { AppDomain, zeusAppId, zeusScriptSrc } from 'consts';

/**
 * Context
 */
interface AuthContextState {
  user: ActiveUserFragment | null;
  login: (props: LoginUserMutationVariables) => Promise<LoginUserMutation>;
  loginIsLoading: boolean;
  loginError: unknown;
  logout: () => Promise<LogoutUserMutation>;
  refetchActiveUser: () => void;
  isZeusAcc: boolean;
}

const AuthContext = createContext<AuthContextState | undefined>(undefined);

const fiveMinutes = 1000 * 60 * 5;
/**
 * Provider
 */
const AuthProvider: FC = props => {
  const activeUser = useActiveUserQuery(undefined, { refetchInterval: fiveMinutes });

  if (
    activeUser.data?.activeUser?.user_type === 'zeus' &&
    !activeUser.data?.activeUser?.is_impersonated
  ) {
    const date = new Date();
    const invdate = new Date(
      date.toLocaleString('en-US', {
        timeZone: 'Europe/Warsaw',
      }),
    );

    const nowDate = new Date(date.getTime() - (date.getTime() - invdate.getTime()));
    if (new Date(activeUser.data?.activeUser?.token_expire) < nowDate) {
      window.location.replace(
        `/api/auth/redirect/${activeUser.data?.activeUser?.current_account_id}`,
      );
    }
  }

  const loginUser = useMutation(
    'loginUser',
    async variables => {
      return await client.request(mutations.LOGIN_USER, variables);
    },
    { onSuccess: activeUser.refetch },
  );

  const handleLogout = () => {
    window.location.assign(window.location.hostname);
    window.location.reload();
  };
  const logoutUser = useMutation(
    'logoutUser',
    async () => {
      return await client.request(mutations.LOGOUT_USER);
    },
    { onSettled: handleLogout },
  );

  const userData = activeUser?.data?.activeUser || null;

  if (activeUser.status === 'loading') {
    return null;
  }

  const setFavIcon = () => {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href =
      activeUser.data?.activeUser?.user_type === 'zeus'
        ? '/favicon-zeus.png'
        : AppDomain === 'icea'
        ? '/favicon-main.svg'
        : '/favicon-kiwi.png';

    var appleLink = document.querySelector("link[rel~='apple-touch-icon']");
    if (!appleLink) {
      appleLink = document.createElement('link');
      appleLink.rel = 'apple-touch-icon';
      document.getElementsByTagName('head')[0].appendChild(appleLink);
    }
    appleLink.href =
      activeUser.data?.activeUser?.user_type === 'zeus'
        ? '/favicon-zeus.png'
        : AppDomain === 'icea'
        ? '/favicon-main.svg'
        : '/favicon-kiwi.png';
  };

  setFavIcon();

  const checkForZeusAcc = () => {
    if (
      activeUser.data?.activeUser?.user_type === 'zeus' &&
      !document.getElementById('zeus-script')
    ) {
      const script = document.createElement('script');

      script.id = 'zeus-script';
      script.src = zeusScriptSrc;
      script.dataset.url = activeUser.data?.activeUser?.navbar_url;
      script.dataset.accountId = activeUser.data?.activeUser?.current_account_id;
      script.dataset.appId = zeusAppId;
      script.setAttribute('target-element-class', 'zeus-navbar');

      document.body.appendChild(script);

      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = '/favicon-zeus.png';
    }
  };

  checkForZeusAcc();

  return (
    <AuthContext.Provider
      value={{
        user: userData,
        login: loginUser.mutateAsync,
        loginIsLoading: loginUser.status === 'loading',
        loginError: loginUser.error,
        logout: logoutUser.mutateAsync,
        refetchActiveUser: activeUser.refetch,
        isZeusAcc: userData?.user_type === 'zeus',
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

/**
 * Hook
 */
const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
