import { isObject } from 'lodash';
import { logger } from '../utils';
import { SortTypeInput } from 'types';

const getNumber = (value: unknown) => {
  if (typeof value === 'number') return value;

  if (typeof value === 'string') return parseInt(value, 10);

  logger("Incorrect value in grid's limit or offset search params");
};

interface IAnyGrid {
  sort?: SortTypeInput;
  limit?: number;
  offset?: number;
  filter?: Record<string, unknown>;
}

export const useGridSearchParams = <TGrid extends IAnyGrid>(
  initialGrid: TGrid,
): [(searchParams: Record<string, unknown>) => TGrid] => {
  const parseParams = (searchParams: Record<string, unknown>) => {
    const newGrid = { ...initialGrid };

    if ('sort' in searchParams) {
      newGrid.sort = searchParams.sort;
    }

    if ('limit' in searchParams) {
      newGrid.limit = getNumber(searchParams.limit);
    }

    if ('offset' in searchParams) {
      newGrid.offset = getNumber(searchParams.offset);
    }

    if ('filter' in searchParams) {
      const currentFilter = isObject(initialGrid.filter) ? initialGrid.filter : {};
      const currentParams = isObject(searchParams.filter) ? searchParams.filter : {};

      newGrid.filter = { ...currentFilter, ...currentParams };
    }

    return newGrid;
  };

  // @ts-ignore
  return [parseParams];
};
