import { gql } from 'graphql-request';

export const TICKET_TODO_FRAGMENT = gql`
  fragment ticketTodo on TicketTodo {
    id
    text
    status
    priority
  }
`;
