import { TicketFragment } from 'types';
import { useQueryClient } from 'react-query';
import { useNotify, useTranslate } from 'hooks';
import { logger } from 'utils';
import { isObject } from 'lodash';

const isTicket = (prop: unknown): prop is TicketFragment => {
  return isObject(prop) && 'file' in prop;
};

export const useSyncTicketFiles = () => {
  const queryClient = useQueryClient();
  const t = useTranslate();
  const { notifyError } = useNotify();

  const syncTicketAttachments = (ticketId: string, files: unknown[]) => {
    const newFiles = files.map(file => ({
      __typename: 'TicketFile',
      id: file.id,
      sizeInKb: file.size_in_kb,
      title: file.title,
    }));

    const currentData = queryClient.getQueryData(['ticket', { id: ticketId }]);

    if (!isTicket(currentData?.ticket)) {
      notifyError(
        t({
          id: 'useSyncTicketFiles.onError.noCurrentTicket.message',
          defaultMessage:
            'There was an error updating displayed ticket files, please reload the page.',
        }),
      );

      logger(
        'useSyncTicketFiles has no currentTicket value in syncTicketAttachments! There was an error updating displayed values, please reload the page.',
      );

      return;
    }

    const ticket = currentData.ticket;

    const syncedTicket = { ...ticket, file: [...ticket.file, ...newFiles] };

    queryClient.setQueryData(['ticket', { id: ticketId }], { ticket: syncedTicket });
  };

  return syncTicketAttachments;
};
