import { gql } from 'graphql-request';

import { TICKET_TODO_FRAGMENT } from '../fragments';

export const ADD_TODO = gql`
  mutation addTodo($ticketId: ID!, $text: String!, $priority: Float) {
    addTodo(ticketId: $ticketId, text: $text, priority: $priority) {
      ...ticketTodo
    }
  }
  ${TICKET_TODO_FRAGMENT}
`;
