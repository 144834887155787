import { gql } from 'graphql-request';

import { USER_BASIC_FRAGMENT } from '../fragments';

export const USERS = gql`
  query users($grid: UserGridInput) {
    users(grid: $grid) {
      rows {
        ...userBasic
      }
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
