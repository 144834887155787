import { get } from 'lodash';
import { UserFragment } from 'types';
import { useUserQuery } from '../__generated__/gqlHooks';

export const useUser = (id: string | undefined) => {
  const { data, isLoading, error, refetch } = useUserQuery(
    { id },
    {
      enabled: !!id,
    },
  );

  return {
    data: get(data, `user`, null) as UserFragment,
    loading: isLoading,
    error,
    refetch,
  };
};
