export const AppDomain =
  window.location.hostname.toLowerCase().indexOf('kiwikick.pl') > -1 ? 'kiwi' : 'icea';
export const AppCompany = AppDomain === 'icea' ? 'iCEA' : 'KiwiKick';

export enum AppInfo {
  NAME = 'Whitelabel Dashboard',
  SHORT_NAME = 'WL',
}

export const CURRENT_YEAR = new Date().getFullYear();

const __VERSION__ = process.env.REACT_APP_VERSION;

const appVersion = __VERSION__ ?? '';

export const APP_VERSION = appVersion;
