import { useEffect } from 'react';
import { useSearch } from '@tanstack/react-location';

import { TableKeys } from 'consts';
import { initialPagination } from 'utils';

import { useGridSearchParams } from './useGridSearchParams';
import { useGridState } from './useGridState';

interface IUseTableGrid<TGrid> {
  tableKey: TableKeys;
  defaultGrid?: TGrid;
  updateDependencies?: string[];
}

export const useTableGrid = <TGrid>({
  tableKey,
  // @ts-ignore
  defaultGrid = {},
  updateDependencies = [],
}: IUseTableGrid<TGrid>) => {
  const initialGrid: TGrid = {
    ...initialPagination(tableKey),
    ...defaultGrid,
  };

  // @ts-ignore
  const [parseParams] = useGridSearchParams<TGrid>(initialGrid);
  const search = useSearch();

  const [grid, updateGrid] = useGridState<TGrid>(parseParams(search));

  useEffect(() => {
    updateGrid(parseParams(search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search, ...updateDependencies]);

  return { grid };
};
