import { gql } from 'graphql-request';

export const COUNTRIES = gql`
  query countries {
    countries {
      id
      key
      value
    }
  }
`;
