import { gql } from 'graphql-request';

import { ACTIVE_USER_FRAGMENT } from '../fragments';

export const ACTIVE_USER = gql`
  query activeUser {
    activeUser {
      ...activeUser
    }
  }
  ${ACTIVE_USER_FRAGMENT}
`;
