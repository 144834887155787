import { gql } from 'graphql-request';
import { DEAL_FRAGMENT } from './deal';

export const DEAL_FRAGMENT__ADMIN = gql`
  fragment dealADMIN on Deal {
    ...deal
    name
    import_id
  }
  ${DEAL_FRAGMENT}
`;
