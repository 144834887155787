import { gql } from 'graphql-request';

import { DOMAIN_IN_THE_LIST_FRAGMENT } from '../fragments';

export const DOMAIN_LIST = gql`
  query domainList($grid: DomainGridInput) {
    domains(grid: $grid) {
      total
      rows {
        ...domainInTheList
      }
    }
  }
  ${DOMAIN_IN_THE_LIST_FRAGMENT}
`;
