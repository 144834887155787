import { gql } from 'graphql-request';

import { DEAL_FRAGMENT } from './deal';
import { PARTNER_USER_FRAGMENT } from './partnerUser';

export const DOMAIN_FRAGMENT = gql`
  fragment domain on Domain {
    id
    name
    first_deal_start_date

    newestAdsDeal {
      ...deal
    }
    newestFbAdsDeal {
      ...deal
    }
    newestSeoDeal {
      ...deal
    }
    newestSxoDeal {
      ...deal
    }
    newestUxDeal {
      ...deal
    }
  }
  ${PARTNER_USER_FRAGMENT}
  ${DEAL_FRAGMENT}
`;
