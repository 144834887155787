import dayjs, { Dayjs, extend } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import isToday from 'dayjs/plugin/isToday';

extend(weekday);
extend(relativeTime);
extend(isToday);

export const formatDateForBE = (value: Dayjs | string) => {
  return dayjs(value).format('YYYY-MM-DD');
};

export const formatDate = (value: string) => {
  return dayjs(value).format('DD MMM YYYY');
};

export const formatDateFullMonth = (value: string) => {
  return dayjs(value).format('DD MMMM YYYY');
};

export const formatDateShortMonth = (value: string) => {
  return dayjs(value).format('DD MMM YYYY');
};

export const formatDateFullMonthAndTime = (value: string) => {
  return dayjs(value).format('DD MMMM YYYY, HH:mm');
};

export const getLastUpdateDate = (value: string) => {
  return formatDate(value);
};

export const formatDateAndTime = (value: string): { date: string; time: string } => ({
  date: dayjs(value).format('DD MMM YYYY'),
  time: dayjs(value).format('HH:mm'),
});

export const todaysDate = () => {
  return dayjs().format('YYYY-MM-DD');
};

export const yesterdaysDate = () => {
  return dayjs().subtract(1, 'day').format('YYYY-MM-DD');
};

export const lastDayThisWeekDate = () => {
  return dayjs().weekday(7).format('YYYY-MM-DD');
};

export const tomorrow = () => {
  return dayjs().add(1, 'day').format('YYYY-MM-DD');
};

export const sevenDaysFromTomorrow = () => {
  return dayjs().add(8, 'day').format('YYYY-MM-DD');
};

export const getRecentActivityDateTime = (value: string) => {
  return dayjs(value).format('DD MMM YYYY HH:mm');
};

export const isDateToday = (date: string) => {
  return dayjs(date).isToday();
};

export const timeFrom = (value: string) => {
  const now = dayjs();

  return dayjs(value).from(now);
};

export const currentWeekDates = (): [string, string] => [
  startOfTheCurrentWeek(),
  endOfTheCurrentWeek(),
];

export const next7Days = () => [
  dayjs().format('YYYY-MM-DD'),
  dayjs().add(7, 'day').format('YYYY-MM-DD'),
];

export const startOfTheCurrentWeek = (): string => dayjs().startOf('week').format('YYYY-MM-DD');

export const endOfTheCurrentWeek = (): string => dayjs().endOf('week').format('YYYY-MM-DD');
