import { gql } from 'graphql-request';

import { TICKET_USERS_FRAGMENT } from '../fragments';

export const CHANGE_TICKET_ASSIGNEE = gql`
  mutation changeTicketAssignee($ticketId: ID!, $assignedUserId: ID!) {
    changeTicketAssignee(ticketId: $ticketId, assignedUserId: $assignedUserId) {
      ...ticketUsers
    }
  }
  ${TICKET_USERS_FRAGMENT}
`;
