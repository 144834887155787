import { gql } from 'graphql-request';

import { ACTIVITY_FRAGMENT } from '../fragments';

export const TICKET_ACTIVITIES = gql`
  query ticketActivities($grid: TicketActivitiesGridInput) {
    ticketActivities(grid: $grid) {
      total
      rows {
        ...activity
      }
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
