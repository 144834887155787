export const setPageTitle = (pageTitle: string, withLabel = true) => {
  setTimeout(() => {
    document.title = withLabel ? `${pageTitle} | Whitelabel Dashboard` : pageTitle;
  }, 0);
};

export const setIncrementalPageTitle = (pageTitle: string) => {
  setTimeout(() => {
    const currentTitle = document.title;

    document.title = `${pageTitle} | ${currentTitle}`;
  }, 0);
};
