import { getContrastRatio, ThemeOptions } from '@mui/material';
import { alpha } from '@mui/material/styles';

const lightThemeBoxShadow = `0px 4px 20px rgba(170, 180, 190, 0.3)`;
const darkThemeBoxShadow = `0px 4px 20px rgba(0, 0, 0, 0.5)`;

export const muiLightTheme: ThemeOptions = {
  typography: {
    fontFamily: [
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#34AEEF',
      dark: '#2479a7',
      light: '#5cbef2',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#2A2B4C',
      dark: '#1d1e35',
      light: '#54556f',
      contrastText: '#ffffff',
    },
    error: {
      main: '#FF6565',
      light: '#ff8383',
      dark: '#b24646',
      contrastText: '#fff',
    },
    success: {
      main: '#2ECC71',
      light: '#57d68d',
      dark: '#208e4f',
      contrastText: '#fff',
    },
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: lightThemeBoxShadow,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: lightThemeBoxShadow,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        style: { boxShadow: lightThemeBoxShadow },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
};

export const muiDarkTheme: ThemeOptions = {
  ...muiLightTheme,
  palette: {
    ...muiLightTheme.palette,
    mode: 'dark',
  },
  components: {
    ...muiLightTheme.components,
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: darkThemeBoxShadow,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: darkThemeBoxShadow,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        style: { boxShadow: darkThemeBoxShadow },
      },
    },
  },
};

const kiwiBase = '#CFFF59';
const kiwiMain = alpha(kiwiBase, 0.8);
const kiwiSecondBase = '#0F0F0F';

export const muiKiwiTheme: ThemeOptions = {
  typography: {
    fontFamily: [
      'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    mode: 'light',
    secondary: {
      main: kiwiMain,
      dark: alpha(kiwiBase, 0.9),
      light: alpha(kiwiBase, 0.5),
      contrastText: '#ffffff',
    },
    primary: {
      main: alpha(kiwiSecondBase, 1),
      dark: '#000000',
      light: alpha(kiwiSecondBase, 0.5),
      contrastText:
        getContrastRatio(alpha(kiwiSecondBase, 0.8), kiwiMain) >= 4.5 ? kiwiMain : '#111',
    },
    error: {
      main: '#FF6565',
      light: '#ff8383',
      dark: '#b24646',
      contrastText: '#fff',
    },
    success: {
      main: '#2ECC71',
      light: '#57d68d',
      dark: '#208e4f',
      contrastText: '#fff',
    },
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: lightThemeBoxShadow,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: lightThemeBoxShadow,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        style: { boxShadow: lightThemeBoxShadow },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiLink: {
      defaultProps: {
        color: '#1f32f2',
      },
    },
  },
};
