import { useIntl } from 'react-intl';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { isObject } from 'lodash';

import { GridSearchParamsTypes, SearchParamsProps } from 'consts';

import { useNotify } from './useNotify';
import { logger } from 'utils';

type UseUpdateGridSearchParams = [(props: SearchParamsProps, newLocation?: string) => void];

const { UPDATE_PAGINATION, UPDATE_SORT, UPDATE_FILTER, REMOVE_FILTER, REMOVE_PARAM } =
  GridSearchParamsTypes;

export const useUpdateGridSearchParams = (): UseUpdateGridSearchParams => {
  /**
   * useLocation has a stale search value here
   */
  const navigate = useNavigate();
  const intl = useIntl();
  const { notifyError } = useNotify();
  const searchParams = useSearch();

  const previousFilters = (obj: unknown) => (isObject(obj) ? obj : {});

  const updateSearchParams = (props: SearchParamsProps, newLocation?: string) => {
    try {
      const newSearchParams = newLocation ? {} : searchParams;

      newSearchParams.limit && delete newSearchParams.limit;
      newSearchParams.offset && delete newSearchParams.offset;

      switch (props.type) {
        case UPDATE_PAGINATION:
          newSearchParams.limit = props.payload.limit;
          newSearchParams.offset = props.payload.offset;

          break;

        case UPDATE_SORT:
          newSearchParams.sort = props.payload;

          break;

        case UPDATE_FILTER:
          // @ts-ignore
          newSearchParams.filter = { ...previousFilters(newSearchParams.filter), ...props.payload };
          break;

        case REMOVE_FILTER:
          if (!isObject(newSearchParams?.filter)) {
            throw new Error('Filter to remove is not an object in updateSearchParams switch!');
          }

          if (typeof props.payload !== 'string') {
            throw new Error('Remove filter payload is not a string in updateSearchParams switch!');
          }

          // @ts-ignore
          delete newSearchParams.filter[props.payload];

          break;

        case REMOVE_PARAM:
          delete newSearchParams[props.payload];

          break;

        default:
          throw new Error('Missed case in updateSearchParams switch!');
      }

      if (newLocation) {
        navigate({
          to: newLocation,
          search: newSearchParams,
        });

        return;
      }

      navigate({
        to: window.location.pathname,
        search: newSearchParams,
      });
    } catch (err: unknown) {
      notifyError(
        intl.formatMessage({
          id: 'useUpdateGridSearchParams.catch.error.message',
          defaultMessage: 'Nastąpił błąd przy przetwarzaniu parametrów',
        }),
      );
      logger(err);
    }
  };

  return [updateSearchParams];
};
