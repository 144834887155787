import { gql } from 'graphql-request';

import { GROUPED_PHRASES_FRAGMENT } from '../fragments';

export const GROUPED_PHRASES = gql`
  query groupedPhrases($grid: GroupedPhraseGridInput) {
    groupedPhrases(grid: $grid) {
      ...groupedPhrases
    }
  }
  ${GROUPED_PHRASES_FRAGMENT}
`;
