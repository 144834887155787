import { gql } from 'graphql-request';

import { USER_BASIC_FRAGMENT } from '../fragments';

export const USER_BASIC = gql`
  query userBasic($id: ID!) {
    user(id: $id) {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
