import { gql } from 'graphql-request';

import { USER_BASIC_FRAGMENT } from './userBasic';

export const USER_IN_THE_LIST_FRAGMENT = gql`
  fragment userInTheList on User {
    id
    first_name
    last_name
    blocked
    email
    phone
    user_type
    notifications
    avatar
    contactUser {
      ...userBasic
    }
    domains_notifications
    supervisor {
      ...userBasic
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
