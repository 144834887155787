import { gql } from 'graphql-request';

import { TICKET_FRAGMENT } from '../fragments';

export const SET_TICKET_PRIORITY = gql`
  mutation setTicketPriority($id: ID!, $priorityValue: Float!) {
    setTicketPriority(id: $id, priorityValue: $priorityValue) {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
