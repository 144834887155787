import { gql } from 'graphql-request';

import { TICKET_USERS_FRAGMENT } from '../fragments';

export const TICKET_USERS = gql`
  query ticketUsers($id: ID!) {
    ticket(id: $id) {
      ...ticketUsers
    }
  }
  ${TICKET_USERS_FRAGMENT}
`;
