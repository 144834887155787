import { gql } from 'graphql-request';

import { EXTERNAL_REPORT_FRAGMENT } from '../fragments';

export const EXTERNAL_REPORTS = gql`
  query externalReports($domainId: ID) {
    externalReports(domainId: $domainId) {
      ...externalReport
    }
  }
  ${EXTERNAL_REPORT_FRAGMENT}
`;
