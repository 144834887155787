import { TableKeys } from 'consts';
import { useMemo, useState } from 'react';
import { GridColumnVisibilityModel } from 'types';
import { logger } from 'utils';
import { isObject } from 'lodash';

const isColumnVisibilityModel = (val: any): val is GridColumnVisibilityModel => {
  try {
    if (!isObject(val)) return false;

    for (const key in val) {
      // @ts-ignore
      if (typeof val?.[key] !== 'boolean')
        throw new Error(
          `isColumnVisibilityModel type guard came across wrong object! ${JSON.stringify(val)}`,
        );
    }

    return true;
  } catch (err: unknown) {
    logger(err);

    return false;
  }
};

const loadColumnVisibility = (tableKey: TableKeys) => {
  try {
    const lsValue = localStorage.getItem(`${tableKey}ColumnVisibility`);

    if (!lsValue) return {};

    const parsedValue = JSON.parse(lsValue);

    if (!isColumnVisibilityModel(parsedValue)) return {};

    return parsedValue;
  } catch (err: unknown) {
    logger(err);

    return {};
  }
};

const saveColumnVisibility = (newModel: GridColumnVisibilityModel, tableKey: TableKeys) => {
  try {
    setTimeout(() => {
      const stringifiedValue = JSON.stringify(newModel);

      localStorage.setItem(`${tableKey}ColumnVisibility`, stringifiedValue);
    }, 0);
  } catch (err: unknown) {
    logger(err);
  }
};

export const useColumnVisibility = (tableKey: TableKeys) => {
  const columnVisibility = useMemo(() => loadColumnVisibility(tableKey), [tableKey]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    () => columnVisibility,
  );

  const onColumnVisibilityModelChange = (newModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(newModel);

    saveColumnVisibility(newModel, tableKey);
  };

  return { columnVisibilityModel, onColumnVisibilityModelChange };
};
