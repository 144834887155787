import { createContext, FC, useContext } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getLocale, IntlProvider } from 'i18n';
import { useUser } from './UserProvider';
import { i18nConfigDefaults, Locales, MuiLocales } from 'consts';

const defaultContextValues = {
  locale: i18nConfigDefaults.locale,
  muiLocale: i18nConfigDefaults.muiLocale,
  messages: {},
};

interface LanguageContextState {
  locale: Locales;
  muiLocale: MuiLocales;
  messages: Record<string, unknown>;
}

const LanguageContext = createContext<LanguageContextState>(defaultContextValues);

/**
 * Provider
 */
const LanguageProvider: FC = props => {
  const user = useUser();
  const userLocale = user?.app_locale;
  const localeData = getLocale(userLocale, defaultContextValues.locale);

  /* useEffect(() => {
   localStorage.setItem('appLocale', localeData.locale);
   }, [localeData.locale]);
   */
  return (
    <IntlProvider
      key={localeData.locale}
      defaultLocale={defaultContextValues.locale}
      locale={localeData.locale}
      messages={localeData.messages}
    >
      <LanguageContext.Provider value={localeData}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>{props.children}</LocalizationProvider>
      </LanguageContext.Provider>
    </IntlProvider>
  );
};

/**
 * Hook
 */
const useLanguage = () => {
  const context = useContext(LanguageContext);

  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }

  return context;
};

export { LanguageProvider, useLanguage };
