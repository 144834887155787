import { get } from 'lodash';
import { UserBasicFragment } from 'types';
import { useUsersQuery } from '../__generated__/gqlHooks';

import { useMemo } from 'react';

interface IUseUsers {
  skip?: boolean;
}

export const useUsers = (props?: IUseUsers) => {
  const { data, ...rest } = useUsersQuery(undefined, { enabled: !props?.skip });

  const users = useMemo(() => get(data, `users.rows`, []) as UserBasicFragment[], [data]);

  return {
    users,
    ...rest,
  };
};
