import { gql } from 'graphql-request';

import { PHRASE_FRAGMENT } from './phrase';

export const GROUPED_PHRASES_FRAGMENT = gql`
  fragment groupedPhrases on GroupedPhrases {
    id
    name
    phrases {
      ...phrase
    }
  }
  ${PHRASE_FRAGMENT}
`;
