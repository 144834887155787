import { gql } from 'graphql-request';

export const USER_BASIC_FRAGMENT = gql`
  fragment userBasic on User {
    id
    first_name
    last_name
    blocked
    avatar
  }
`;
