import { gql } from 'graphql-request';

import { CLIENT_SETTINGS_FRAGMENT } from '../fragments';

export const UPDATE_CLIENT_SETTINGS = gql`
  mutation updateClientSettings($id: ID!, $userInput: UpdateUserInput!) {
    updateUser(id: $id, userInput: $userInput) {
      ...clientSettings
    }
  }
  ${CLIENT_SETTINGS_FRAGMENT}
`;
