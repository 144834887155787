/* eslint-disable no-console */
// type ErrorWithMessage = {
//   message: string;
// };

// function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
//   return (
//     typeof error === 'object' &&
//     error !== null &&
//     'message' in error &&
//     typeof (error as Record<string, unknown>).message === 'string'
//   );
// }

// function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
//   if (isErrorWithMessage(maybeError)) return maybeError;

//   try {
//     return new Error(JSON.stringify(maybeError));
//   } catch {
//     // fallback in case there's an error stringifying the maybeError
//     // like with circular references for example.
//     return new Error(String(maybeError));
//   }
// }

// function getErrorMessage(error: unknown) {
//   return toErrorWithMessage(error).message;
// }

export const logger = (message: unknown) => {
  console.error(message);
  // reportError({message: getErrorMessage(error)})

  if (typeof message === 'string') {
    console.debug(message);

    return;
  }

  console.log(JSON.stringify(message, null, 2));
};
