import { gql } from 'graphql-request';

export const USER_STATISTICS_DETAILS_FRAGMENT = gql`
  fragment userStatisticsDetails on Details {
    id
    thisWeek
    overdue
    today
    open
    closed
    domains
  }
`;
