import { gql } from 'graphql-request';

export const IMPERSONATE = gql`
  mutation impersonate($userId: ID!, $accountId: String) {
    impersonate(userId: $userId, accountId: $accountId) {
      email
      first_name
      last_name
    }
  }
`;
