import { gql } from 'graphql-request';

export const EXTERNAL_REPORT_FRAGMENT = gql`
  fragment externalReport on ExternalReportDTO {
    id
    domainId
    domainName
    language
    link
    emailSent
    reportDate
  }
`;
