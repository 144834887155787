import { useQueryClient } from 'react-query';
import { logger } from '../utils';
import { TicketCommentsGridInput } from 'types';

export const useSyncTicketCommentFiles = (grid: TicketCommentsGridInput) => {
  const queryClient = useQueryClient();

  const syncTicketCommentFiles = (commentId: string, files: unknown[]) => {
    const newFiles = files.map(file => ({
      __typename: 'TicketCommentFile',
      id: file.id,
      sizeInKb: file.size_in_kb,
      title: file.title,
    }));

    const previousComments = queryClient.getQueryData(['ticketComments', { grid }]);
    if (!previousComments) {
      logger('There was an error updating displayed comments. No previousComments found');

      return;
    }

    const comments = previousComments.ticketComments.rows;

    const updatedComments = comments.map(comment => {
      if (comment.id !== commentId) return comment;

      return { ...comment, file: [...comment.file, ...newFiles] };
    });

    queryClient.setQueryData(['ticketComments', { grid }], {
      ticketComments: {
        total: previousComments.ticketComments.total,
        rows: updatedComments,
      },
    });
  };

  return syncTicketCommentFiles;
};
