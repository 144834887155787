import { gql } from 'graphql-request';

import { USER_FRAGMENT } from '../fragments';

export const USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
