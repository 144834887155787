import { lazy, Suspense } from 'react';
import './fonts.css';

import { useUser } from 'context/UserProvider';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));
const UnauthenticatedApp = lazy(() => import('scenes/UnauthenticatedApp'));

export const App = () => {
  const user = useUser();
  return (
    <Suspense fallback={null}>{user ? <AuthenticatedApp /> : <UnauthenticatedApp />}</Suspense>
  );
};
