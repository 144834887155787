import { gql } from 'graphql-request';

import { TICKET_STATUS_FRAGMENT } from '../fragments';

export const TICKET_STATUSES = gql`
  query ticketStatuses($includeArchived: Boolean) {
    ticketStatuses(includeArchived: $includeArchived) {
      ...status
    }
  }
  ${TICKET_STATUS_FRAGMENT}
`;
