/* eslint-disable no-dupe-else-if */
/* eslint-disable no-constant-condition */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/react';
import { SamplingContext } from '@sentry/types/dist/transaction';

import { apiEndpoint, APP_VERSION, isDev, isLocal, isProd, isStage } from 'consts';

const tunnel = `${apiEndpoint}/sentry`;
const release = `wl@${APP_VERSION}`;

const ignoreErrors = [
  /*'AbortError',
   'ResizeObserver loop limit exceeded',
   'ResizeObserver loop completed with undelivered notifications',
   'props.blockProps.setFocusToBlock is not a function',
   'r.blockProps.setFocusToBlock is not a function',
   'ChunkLoadError',
   "TypeError: Cannot read property 'getBoundingClientRect' of null",
   "Cannot read property 'getCharacterList' of undefined",
   'Minified exception occurred; use the non-minified dev environment for the full error message and additional helpful warnings.',
   'Loading chunk',
   'jQuery is not defined',
   'Unexpected token',
   "Cannot read properties of null (reading 'getBoundingClientRect')",
   "Cannot read properties of undefined (reading 'getCharacterList')",
   '[React Intl] Could not find required `intl` object. <IntlProvider> needs to exist in the component ancestry.',
   'Uncaught TypeError: Cannot convert undefined or null to object',
   '(vendors.',*/
];

/*const integrations = [
 new Integrations.BrowserTracing({
 routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
 }),
 ];*/

const dsn = 'https://5baa9016521a4d198371e85750d05dcd@o553304.ingest.sentry.io/6355692';

const beforeSend = (event: Sentry.Event, hint: Sentry.EventHint | undefined) => {
  if (isLocal) {
    console.debug(
      '🚀 ~ file: sentrySettings.ts ~ line 42 ~ beforeSend ~ event, ',
      event,
      '--hint--:',
      hint,
    );

    return null;
  }

  return {
    ...event,
    tags: {
      ...event.tags,
      app_url: window.location.href,
    },
  };
};

const tracesSampler = (samplingContext: SamplingContext) => {
  if (isLocal) {
    console.log('file: sentrySettings.ts ~ line 44 ~ samplingContext', samplingContext);

    return 0;
  }
  // Examine provided context data (including parent decision, if any) along
  // with anything in the global namespace to compute the sample rate or
  // sampling decision for this transaction
  // contents of `samplingContext`
  /*  {
   transactionContext: {
   name: string; // human-readable identifier, like "GET /users"
   op: string; // short description of transaction type, like "pageload"
   }
   parentSampled: boolean; // if this transaction has a parent, its sampling decision
   location: Location | WorkerLocation; // the window.location or self.location object
   ... // custom context as passed to `startTransaction`
   } */

  return 0.1;
};

const environment = () => {
  if (isProd) return 'production';
  if (isStage) return 'staging';
  if (isDev) return 'development';
  return 'local';
};

export const sentrySettings: Sentry.BrowserOptions = {
  tracesSampler,
  beforeSend,
  environment: environment(),
  dsn,
  tunnel,
  /* integrations,
   ignoreErrors,*/
  release,
  tracesSampleRate: 0.1,
  sampleRate: 0.5,
  autoSessionTracking: true,
};

export const reportToSentry = (err: unknown) => {
  if (typeof err === 'string') {
    Sentry.captureMessage(err);

    return;
  }

  Sentry.captureException(err);
};
