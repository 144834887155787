import { gql } from 'graphql-request';

import { TICKET_TYPE_FRAGMENT } from '../fragments';

export const UPDATE_TICKET_TYPE = gql`
  mutation updateTicketType(
    $id: ID!
    $value: String
    $archived: Boolean
    $color: ColorPaletteEnum
  ) {
    updateTicketType(
      id: $id

      value: $value
      archived: $archived
      color: $color
    ) {
      ...type
    }
  }
  ${TICKET_TYPE_FRAGMENT}
`;
