import { gql } from 'graphql-request';

import { DOMAIN_FRAGMENT } from '../fragments';

export const DOMAIN = gql`
  query domain($id: ID!) {
    domain(id: $id) {
      ...domain
    }
  }
  ${DOMAIN_FRAGMENT}
`;
