import { gql } from 'graphql-request';

/**
 * It must have a domain id field.
 * Domain field is bounded to monitor-domain
 * monitor by itself does not have domain reference
 * and a request for domain must be triggered to
 * fetch the data.
 */
export const MONITOR_FRAGMENT = gql`
  fragment monitor on Monitor {
    id
    monitor_id
    friendly_name
    url
    status
    daily_uptime_ratio
    weekly_uptime_ratio
    monthly_uptime_ratio
    domain {
      id
    }
  }
`;
