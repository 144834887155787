import { gql } from 'graphql-request';

export const DEAL_IN_THE_DOMAIN_LIST_FRAGMENT = gql`
  fragment dealInTheDomainList on Deal {
    id
    active
    type
    type_name
    currency
    orig_real_start_date
    amount
  }
`;
