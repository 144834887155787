import { useSnackbar, VariantType } from 'notistack';

const variants: Record<string, VariantType> = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

export const useNotify = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notifySuccess = (message: string) => {
    enqueueSnackbar(message, { variant: variants.success });
  };

  const notifyError = (message: unknown) => {
    if (typeof message === 'string') {
      enqueueSnackbar(message, { variant: variants.error });

      return;
    }

    const errors = message?.response?.errors;
    if (!!errors?.length) {
      errors.forEach((err: { message: string }) =>
        enqueueSnackbar(err?.message, { variant: variants.error }),
      );
    }

    const extra = message?.response?.extra;
    if (!!extra?.length) {
      extra.forEach((text: string) => enqueueSnackbar(text, { variant: variants.error }));
    }
  };

  const notifyWarning = (message: string) => {
    enqueueSnackbar(message, { variant: variants.warning });
  };

  const notifyInfo = (message: string) => {
    enqueueSnackbar(message, { variant: variants.info });
  };

  return { notifySuccess, notifyError, notifyInfo, notifyWarning };
};
