import { gql } from 'graphql-request';

import { DOMAIN_FRAGMENT__ADMIN } from '../fragments';

export const DOMAIN__ADMIN = gql`
  query domainADMIN($id: ID!) {
    domain(id: $id) {
      ...domainADMIN
    }
  }
  ${DOMAIN_FRAGMENT__ADMIN}
`;
