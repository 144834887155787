import { get } from 'lodash';
import { GroupedPhrasesQueryVariables } from 'types';
import { useGroupedPhrasesQuery } from 'hooks';
import { useMemo } from 'react';

export const useGroupedPhrases = (variables: GroupedPhrasesQueryVariables) => {
  const { data, isLoading, error, refetch } = useGroupedPhrasesQuery(variables);

  const groupedPhrases = useMemo(() => get(data, `groupedPhrases`, []), [data]);

  return {
    groupedPhrases,
    isLoading,
    error,
    refetch,
  };
};
