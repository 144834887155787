import { gql } from 'graphql-request';

export const TICKET_IN_THE_LIST_FRAGMENT = gql`
  fragment ticketInTheList on Ticket {
    id
    is_imported
    title
    start
    deadline
    updated_at
    created_at

    type {
      id
      value
      color
    }
    status {
      id
      value
      color
    }
    importType {
      id
      value
      color
    }
    importStatus {
      id
      value
      color
    }
  }
`;
