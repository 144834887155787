import { FC, lazy, Suspense } from 'react';

import { Locales } from 'consts';

/**
 * Interfaces & Types
 */
interface I18nProviderState {
  locale: Locales;
  defaultLocale: Locales;
  messages: Record<string, unknown>;
}

/**
 * Component
 */
const Provider: FC<I18nProviderState> = props => {
  const { locale, children, defaultLocale, messages } = props;
  const lang = locale.split('-')[0];
  const IntlProvider = lazy(() => import(`./providers/${lang}`));

  return (
    <Suspense fallback={null}>
      <IntlProvider locale={locale} defaultLocale={defaultLocale} messages={messages}>
        {children}
      </IntlProvider>
    </Suspense>
  );
};

export default Provider;
