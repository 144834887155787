import { gql } from 'graphql-request';

import { ROLE_IN_THE_LIST_FRAGMENT } from '../fragments';

export const ROLES = gql`
  query roles {
    roles {
      ...roleInTheList
    }
  }
  ${ROLE_IN_THE_LIST_FRAGMENT}
`;
