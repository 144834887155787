import { UpdateRoleMutation, UpdateRoleMutationVariables } from 'types';
import { useUpdateRoleMutation } from 'hooks';

interface IHandleUpdateRole {
  variables: UpdateRoleMutationVariables;
}

export const useUpdateRole = (): [
  ({ variables }: IHandleUpdateRole) => Promise<UpdateRoleMutation>,
] => {
  const { mutateAsync } = useUpdateRoleMutation();

  const handleUpdateRole = ({ variables }: IHandleUpdateRole) => {
    return mutateAsync(variables);
  };

  return [handleUpdateRole];
};
