import { DefaultTheme } from 'styled-components';
import { sizes } from './consts';

export const theme: DefaultTheme = {
  colors: {
    white: '#fff',
    black: '#000',
    grey: '#f5f5f5',
    grey2: '#d2d2d2',
    grey1: '#e2e3e4',
    grey3: '#e5e5e5',
    grey4: '#e0e0e0',
    grey5: '#e2e2e4',
    grey6: '#e8e8e8',
    grey7: '#bfbfbf',
    grey8: '#656b80',
    grey9: '#f2f1f1',
    lightGrey: '#F8F8F8',
    lightGrey2: '#f0f0f0',
    lightGrey4: '#fafafa',
    lightGrey5: '#b7b7b7',
    lightGreyTableRowBody: '#ddd',
    darkGrey: '#808494',
    darkGrey2: '#455a64',
    darkGrey3: '#333',
    darkGrey4: '#3a3b3f',
    blue: '#03a9f4',
    blue1: '#3c87a8',
    blue2: '#2196f3',
    blue3: '#3c87a8',
    blue4: '#3e3f61',
    red: '#f44336',
    redDangerDark: '#f55246',
    redDangerLight: '#fde1df',
    redDangerText: '#7f231c',
    greenSuccessDark: '#87d620',
    greenSuccessLight: '#e9f6d8',
    greenSuccessText: '#589b00',
    yellowWarningDark: '#f6c500',
    yellowWarningLight: '#fff9e2',
    yellowWarningText: '#b18e00',
    blueInfoDark: '#25c3f3',
    blueInfoLight: '#f2fcff',
    blueInfoText: '#017ca1',
    teal: '#3c87a8',
    darkBlue: '#2A2B4C',
    lightBlue: '#34AEEF',
    warningRed: '#EE2058',
    textGrey: '#808495',
    notifRed: '#FF6565',
    lightRed: '#f0202047',
    lightRed2: '#f0202014',
    borderGrey: '#F1F1F3',
    navyBlue: '#252643',
    backgroundGrey: '#F3F3F3',
    readyGreen: '#4AD991',
    disabledGrey: '#D4D4D4',
    deadBlue: '#40415E',
    green: '#2ECC71',
    lightGreen: '#2aff3052',
    lightGreen2: '#2aff301c',
    greenHover: '#28B262',
    purple: '#C67FDB',
    yellow: '#FEBE3E',
    yellow2: '#f5d405',
    azure: '#F5FDFF',
    greyHover: '#E8E8EA',
    shadyBlue: '#40405E',
    lightBlueHover: '#2D98D1',
    whiteHover: '#F4F4F4',
    redHover: '#DF5858',
    iconGrey: '#83919a',
    iconYellow: '#eed220',
    paleRed: '#fff7f5',
    paleBlue: '#f7f5ff',
    darkOrange: '#e67e22',
    scaleGreen: '#7bc49f',
    scaleLightGreen: '#8cbe21',
    scaleGreenYellow: '#e2de09',
    scaleYellow: '#f9d716',
    scaleOrange: '#f8ad13',
    scaleDarkOrange: '#f07b07',
    scaleRed: '#e84c19',
    midnightBlue: '#1a237e',
    fadedBlue: '#3498db',
    rejectionRed: '#ff0000',
    madisonBlue: '#2c3e50',
    pink: '#ffaed7',
    darkGreen: '#00b24b',
    green4: '#62d68a',
    green3: '#95e1ad',
    green2: '#c0edcd',
  },
  shadows: {
    lightLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.15)',
    lightRight: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.15)',
    darkLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.7)',
    darkRight: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.7)',
  },
  border: {
    radius: {
      small: '2px',
      semiMedium: '3px',
      medium: '4px',
      big: '5px',
      large: '10px',
    },
  },
  font: {
    size: {
      xsmall: '10px',
      small: '11px',
      semiSmall: '12px',
      regular: '13px',
      semiMedium: '14px',
      medium: '15px',
      mediumPlus: '16px',
      mediumBig: '17px',
      semiBig: '18px',
      titleBig: '20px',
      numberBig: '23px',
      big: '25px',
      statusBig: '26px',
      semiLarge: '32px',
      large: '38px',
      xlarge: '56px',
    },
    weight: {
      light: 300,
      normal: 'normal',
      semiBold: 600,
      bold: 700,
      black: 900,
    },
  },
  devices: {
    mobileS: `(max-width: ${sizes.xs})`,
    mobileM: `(max-width: ${sizes.sm})`,
    mobileL: `(max-width: ${sizes.md})`,
    tablet: `(max-width: ${sizes.lg})`,
    laptopS: `(max-width: ${sizes.xl})`,
    laptopM: `(max-width: 1400px)`,
    laptopL: `(max-width: ${sizes.xxl})`,
  },
};
