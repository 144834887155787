import { gql } from 'graphql-request';

export const MONITOR_LIMITS = gql`
  query monitorLimits {
    monitorLimits {
      monitors
      monitorsLimit
      upMonitors
      downMonitors
    }
  }
`;
