import { DependencyList, RefObject, useEffect, useLayoutEffect } from 'react';

interface IUseHorizontalScrollPosition {
  effect: ({ isStart, isEnd }: { isStart: boolean; isEnd: boolean }) => void;
  deps: DependencyList | undefined;
  innerRef: RefObject<HTMLDivElement>;
  wait?: number;
}

const isBrowser = typeof window !== `undefined`;

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const useHorizontalScrollPosition = (props: IUseHorizontalScrollPosition) => {
  const { effect, deps, innerRef, wait = 200 } = props;

  let throttleTimeout: unknown = null;

  const callBack = () => {
    if (!innerRef?.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = innerRef.current;

    const isStart = scrollLeft === 0;
    const isEnd = scrollWidth - (scrollLeft + clientWidth) < 2;
    effect({ isStart, isEnd });

    throttleTimeout = null;
  };

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) return undefined;

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    innerRef?.current?.addEventListener('scroll', handleScroll, { passive: true });

    return () => innerRef?.current?.removeEventListener('scroll', handleScroll);
  }, deps);
};
