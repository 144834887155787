import { gql } from 'graphql-request';

export const USER_STATISTICS = gql`
  query userStatistics($id: ID!) {
    userStatistics(id: $id) {
      thisWeek
      overdue
      today
      open
      closed
    }
  }
`;
