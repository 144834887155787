import { gql } from 'graphql-request';
import { USER_BASIC_FRAGMENT } from './userBasic';

export const PARTNER_USER_FRAGMENT = gql`
  fragment partnerUser on User {
    id
    first_name
    last_name
    contactUser {
      ...userBasic
    }
    avatar
  }
  ${USER_BASIC_FRAGMENT}
`;
