import { createGlobalStyle } from 'styled-components';
import 'react-image-lightbox/style.css';

import LatoRegular from 'static/fonts/Lato-Regular.ttf'; // @ts-ignore
import LatoBold from 'static/fonts/Lato-Bold.ttf'; // @ts-ignore
import LatoBlack from 'static/fonts/Lato-Black.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    src: url('${LatoRegular}') format('ttf'),
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    src: url('${LatoBold}') format('ttf'),
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: url('${LatoBlack}') format('ttf'),
  }
`;
