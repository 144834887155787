import { gql } from 'graphql-request';

import { TICKET_COMMENT_FRAGMENT } from '../fragments';

export const CREATE_TICKET_COMMENT = gql`
  mutation createTicketComment($ticketId: ID!, $contentHtml: String!) {
    createTicketComment(ticketId: $ticketId, contentHtml: $contentHtml) {
      ...ticketComment
    }
  }
  ${TICKET_COMMENT_FRAGMENT}
`;
