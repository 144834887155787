import { createContext, FC, useContext } from 'react';

import { useAuth } from './AuthProvider';
import { UserFragment } from 'types';

/**
 * Context
 */
const UserContext = createContext<UserFragment | null | undefined>(undefined);

/**
 * Provider
 */
const UserProvider: FC = props => {
  const { user } = useAuth();

  return <UserContext.Provider value={user}>{props.children}</UserContext.Provider>;
};

/**
 * Hook
 */
const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};

export { UserProvider, useUser };
