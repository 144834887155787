import { gql } from 'graphql-request';

export const PHRASE_FRAGMENT = gql`
  fragment phrase on Phrase {
    id
    value
    start_position
    user_assigned_url
    location
    location_key
    positionsOrdered {
      date
      position
      url
      positionDifference
    }
    search {
      id
      count
    }
    group {
      id
      name
    }
    domain {
      id
    }
  }
`;
