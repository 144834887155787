import { gql } from 'graphql-request';

export const ROLE_IN_THE_LIST_FRAGMENT = gql`
  fragment roleInTheList on RoleResponse {
    id
    name
    title
    permissions
  }
`;
