import { gql } from 'graphql-request';

import { TICKET_FRAGMENT } from '../fragments';

export const CREATE_TICKET = gql`
  mutation createTicket(
    $title: String!
    $contentHtml: String
    $typeId: ID
    $deadline: DateTime
    $start: DateTime
    $assignedToTicketId: ID
  ) {
    createTicket(
      title: $title
      contentHtml: $contentHtml
      typeId: $typeId
      deadline: $deadline
      start: $start
      assignedToTicketId: $assignedToTicketId
    ) {
      ...ticket
    }
  }
  ${TICKET_FRAGMENT}
`;
