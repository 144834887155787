import { gql } from 'graphql-request';

import { IMPORTED_TICKET_FRAGMENT } from '../fragments';

export const IMPORTED_TICKET = gql`
  query importedTicket($id: ID!) {
    ticket(id: $id) {
      ...importedTicket
    }
  }
  ${IMPORTED_TICKET_FRAGMENT}
`;
