import { gql } from 'graphql-request';

import { TICKET_COMMENT_FILE_FRAGMENT } from './ticketCommentFile';
import { USER_BASIC_FRAGMENT } from './userBasic';

export const TICKET_COMMENT_FRAGMENT = gql`
  fragment ticketComment on TicketComment {
    id
    content_html
    created_at
    user {
      ...userBasic
    }
    file {
      ...ticketCommentFile
    }
  }
  ${USER_BASIC_FRAGMENT}
  ${TICKET_COMMENT_FILE_FRAGMENT}
`;
