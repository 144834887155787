/* eslint-disable */

// @ts-ignore
import React from 'react';
// @ts-ignore
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {logRocketSettings} from './logRocketSettings';
import {sentrySettings} from './sentrySettings';
import {isDev, isProd} from 'consts';
import {logger} from './logger';

// @ts-ignore
const initLogRocket = () => {
  LogRocket.init('nw0r6z/pwl-9kmdw', logRocketSettings);
  setupLogRocketReact(LogRocket);
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
};

/* const initAxeCore = () => {
 // eslint-disable-next-line @typescript-eslint/no-var-requires
 const axe = require('@axe-core/react') as typeof import('@axe-core/react');
 axe(React, ReactDOM, 1000);
 };
 */
export const initLogServices = () => {
  try {
    if (isDev || isProd) {
      Sentry.init(sentrySettings);
      initLogRocket();
    }

    /*   if (isLocal) {
     initAxeCore();
     } */
  } catch (e: unknown) {
    logger(e);
  }
};
