import { gql } from 'graphql-request';

import { USER_BASIC_FRAGMENT } from '../fragments';

export const USER_ACTIVITY_STREAM = gql`
  query userActivityStream(
    $id: ID!
    $limit: Int
    $offset: Int
    $sort: SortTypeInput
    $date: String
  ) {
    userActivityStream(id: $id, limit: $limit, offset: $offset, sort: $sort, date: $date) {
      date
      total
      items {
        id
        actor_id
        user {
          ...userBasic
        }
        object_id
        object_type
        summary
        summary_template
        payload
        summary_vars
        created_at
      }
    }
  }
  ${USER_BASIC_FRAGMENT}
`;
