import { gql } from 'graphql-request';

import { USER_FRAGMENT } from '../fragments';

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String
    $role: String
    $crmCompanyId: Int
    $contactUserId: ID
    $userType: UserTypeEnum
    $supervisorId: ID
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      role: $role
      crmCompanyId: $crmCompanyId
      contactUserId: $contactUserId
      userType: $userType
      supervisorId: $supervisorId
    ) {
      ...user
    }
  }
  ${USER_FRAGMENT}
`;
