import { gql } from 'graphql-request';

import { TICKET_STATUS_FRAGMENT } from '../fragments';

export const UPDATE_TICKET_STATUS = gql`
  mutation updateTicketStatus(
    $id: ID!
    $value: String
    $treatAsClosed: Boolean
    $archived: Boolean
    $color: ColorPaletteEnum
    $visibleInKanban: Boolean
    $priority: Float
  ) {
    updateTicketStatus(
      id: $id
      value: $value
      treatAsClosed: $treatAsClosed
      archived: $archived
      color: $color
      visibleInKanban: $visibleInKanban
      priority: $priority
    ) {
      ...status
    }
  }
  ${TICKET_STATUS_FRAGMENT}
`;
